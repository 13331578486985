<template>
  <v-card class="elevation-5">
    <v-card-text class="pl-9 pt-8 pb-1">
      <span class="headline">
        {{ $t('Create a new organisation') }}
      </span>
    </v-card-text>
    <v-container class="pl-9 pr-9">
      <div>
        <v-form v-model="validForm">
          <v-row>
            <v-col lg="6">
              <v-text-field
                v-model="name"
                :label="$t('Name')"
                filled
                required
                :rules="$rules.required"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Implementoidaan myöhemmin
      <v-row>
        <v-col lg="6">
          <v-text-field
            v-model="logo_uri"
            :label="$t('Logo uri')"
            filled
          ></v-text-field>
        </v-col>

        <v-col lg="6">
          <v-text-field
            v-model="brand_color"
            :label="$t('Brand color')"
            filled
          ></v-text-field>
        </v-col>
      </v-row>
       -->
          <v-row>
            <v-col class="text-right">
              <v-btn
                justify-end
                color="primary"
                large
                :disabled="!validForm"
                :loading="submitting"
                @click="createOrganisationHandler"
              >
                {{ $t('Create') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </v-card>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    submitting: false
  }),
  computed: {
    name: {
      get() {
        return this.$store.state.organisations.editor.name
      },
      set(value) {
        this.$store.commit('organisations/set', ['editor.name', value])
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit('is-valid', value)
    }
  },
  methods: {
    async createOrganisationHandler() {
      let me = this
      me.submitting = true
      try {
        let org = await me.$store.dispatch('organisations/createOrganisation')
        me.$toast({
          type: 'success',
          message: `New organisation "${org.name}" created`
        })
        await me.$redirect({
          name: 'users',
          params: { organisationId: org.id }
        })
      } catch (err) {
        me.$error(err)
      }
      me.submitting = false
    }
  }
}
</script>
